import Vue from "vue";

import "./antd";

// table mixin
import mixin from "./mixin";
Vue.use(mixin);


// utils
import utils from "./utils";
Vue.prototype.$utils = utils;


// 过滤器
import filters from "./filters";
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});
