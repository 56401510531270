export default [
  {
    path: "dashboard",
    name: "overview",
    meta: {
      title: "首页概览",
      icon: "dashboard",
    },
    component: () => import("@/views/dashboard"),
  },
  {
    path: "company",
    name: "company",
    meta: {
      title: "公司管理",
      icon: "apartment",
    },
    component: () => import("@/views/company"),
  },
  {
    path: "account",
    name: "account",
    meta: {
      title: "账号管理",
      icon: "user",
    },
    component: () => import("@/views/account"),
  },
  {
    path: "manage",
    name: "site",
    meta: {
      title: "站点管理",
      icon: "environment",
      hiddenMenu: true,
    },
    redirect: {
      name: "sitemanage",
    },
    component: () => import("@/views/site"),
    children: [
      {
        path: "site/index",
        name: "sitemanage",
        meta: {
          title: "站点管理",
          selectMenu: "site",
        },
        component: () => import("@/views/site/manage"),
      },
      {
        path: "site/detail",
        name: "sitedetail",
        meta: {
          title: "单站管理",
          selectMenu: "site",
        },
        component: () => import("@/views/site/detail"),
      },
    ],
  },
  {
    path: "warning",
    name: "warning",
    meta: {
      title: "电站预警",
      icon: "thunderbolt",
    },
    component: () => import("@/views/warning"),
  },
  {
    path: "maintain",
    name: "maintain",
    meta: {
      title: "维护通知",
      icon: "cloud-upload",
    },
    component: () => import("@/views/maintain"),
  },
  // ,
  // {
  // 	path: "operation",
  // 	name: "operation",
  // 	meta: {
  // 		title: "运维管理",
  // 		icon: "tool"
  // 	},
  // 	component: () => import("@/views/operation")
  // }
];
