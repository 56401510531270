import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// 是否在前台
		is_frontend: true,
		
		// 用户信息
		_userInfo: JSON.parse(localStorage.getItem("_userInfo") || "{}"),

		// 权限
		_userAuth: JSON.parse(localStorage.getItem("_userAuth") || "{}")
	},
	mutations: {
		setState(obj) {
			for (let key in obj) {
				if (Reflect.has(this.state, key)) {
					this.state[key] = obj[key];
				}
			}
		}
	},
	actions: {},
	modules: {}
});
