export default [
	{
		path: "admin",
		name: "admin",
		meta: {
			title: "后台管理"
		},
		redirect: { name: "account" },
		component: () => import("@/views/admin"),
		children: [
			{
				path: "account",
				name: "backAccount",
				meta: {
					title: "账号管理",
					icon: "user"
				},
				component: () => import("@/views/admin/account")
			},
			{
				path: "role",
				name: "backRole",
				meta: {
					title: "角色管理",
					icon: "solution",
				},
				component: () => import("@/views/admin/role")
			},
			{
				path: "personal",
				name: "personal",
				meta: {
					title: "个人中心"
				},
				component: () => import("@/views/admin/personal")
			}
		]
	}
];
