export default {
	// 基础单位换算
	unitFilters: (value, unit = "", valid) => {
		// 默认是数据本身 保留1位
		let step = 1,
			decimals = 1;
		switch (unit.toLowerCase()) {
			case "kwh":
				step = 3600 * 1000;
				decimals = 1;
				break;
			case "mwh":
				step = 3600 * 1000 * 1000;
				decimals = 1;
				break;
			case "kw":
				step = 1000;
				decimals = 1;
				break;
			case "mw":
				step = 1000 * 1000;
				decimals = 1;
				break;
			case "kvar":
				step = 1000;
				decimals = 1;
				break;
			case "mvar":
				step = 1000 * 1000;
				decimals = 1;
				break;
			case "k":
				// 通用除1000 kA kV kΩ kxxx 等
				step = 1000;
				decimals = 1;
				break;
			case "万元":
				step = 10000;
				decimals = 2;
				break;
			default:
				step = 1;
				decimals = 1;
		}
		// 外部有传入有效位数
		if (valid) decimals = valid;
		// 换算
		if (value) {
			let result = value / step;
			if (result % 1 === 0) return result;
			return result.toFixed(decimals);
		}
		if (value === 0) return 0;
		return "-";
	},
	// 图表展示单位换算
	chartUnitFilters: (val, step = 1, valid = 1) => {
		if (val) return (val / step).toFixed(valid);
		if (val == 0) return 0;
		// null的情况
		return val;
	}
};
