import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const frontend = require("./front.router").default;
const backend = require("./back.router").default;
const routes = [
	{
		path: "/login",
		name: "login",
		meta: {
			title: "登录页"
		},
		component: () => import("@/views/login")
	},
	{
		path: "/",
		redirect: "/login",
		component: () => import("@/layouts/AdminLayout"),
		/**
		 * meta: {
		 *      title: 导航名称
		 *      icon: 侧边导航图标
		 *      hiddenMenu: true children不出现在侧边导肮
		 * }
		 */
		children: [...frontend, ...backend],
	},
	{
		path: "*",
		component: () => import("@/views/error")
	}
];

const router = new VueRouter({
	routes
});

// router.beforeEach((to, from, next) => {

// 	if (to.name === "login") {
// 		localStorage.clear();
// 		sessionStorage.clear();
// 		next();
// 	}

// 	next();
// });

export default router;
