import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";

// plugins 所有自定义的全局使用
import "./plugins";

Vue.config.productionTip = false;

Vue.prototype.$moment = moment;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
