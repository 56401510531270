import Vue from "vue";

import {
  ConfigProvider,
  Layout,
  Form,
  Input,
  Button,
  Icon,
  Menu,
  Dropdown,
  Result,
  Descriptions,
  Table,
  Row,
  Col,
  Modal,
  Select,
  Popconfirm,
  Divider,
  DatePicker,
  InputNumber,
  message,
  Upload,
  Tree,
  Badge,
  Switch,
  Radio,
  TimePicker,
} from "ant-design-vue";

Vue.use(ConfigProvider);
Vue.use(Layout);
Vue.use(Form);
Vue.use(Input);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Result);
Vue.use(Descriptions);
Vue.use(Table);
Vue.use(Row);
Vue.use(Col);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Popconfirm);
Vue.use(Divider);
Vue.use(DatePicker);
Vue.use(InputNumber);
Vue.use(Upload);
Vue.use(Tree);
Vue.use(Badge);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(TimePicker);
Vue.prototype.$message = message;
