import moment from "moment";

export default {

	/**
	 *	时间戳（秒）转换成 *小时*分*秒
	 */
	formatTime(timer) {
		if (!timer) {
			return "-";
		} else {
			if (timer < 0) return timer + "秒";
			const x = timer * 1000;
			const tempTime = moment.duration(x);

			let timeString = tempTime.hours() + "小时";
			if (timer >= 86400) {
				const hours = tempTime.days() * 24 + tempTime.hours();
				timeString = hours + "小时";
			}
			timeString =
				timeString +
				tempTime.minutes() +
				"分" +
				tempTime.seconds() +
				"秒";
			if (timer < 3600) {
				timeString =
					tempTime.minutes() + "分" + tempTime.seconds() + "秒";
			}
			return timeString;
		}
	},

	/**
	 *	格式化数字、字符串，小数位数默认2位
	 *  例：电压精确小数点3位
	 *      0  -> 0
	 * 	    0.00  -> 0
	 *      12  -> 12
	 *      12.01  -> 12.01
	 *      12.145  ->  12.145
	 *      12.1456  ->  12.146
	 *      12.1451  ->  12.145
	 *      12.1599  ->  12.16
	 *      12.1999  ->  12.2
	 *      12.9999  ->  13
	 *      -0.0012  ->  0
	 *      -0.00000001  ->  0
	 */
	toFixed(val, length = 2) {
		if (
			val === "undefined" ||
			val === "null" ||
			val === "" ||
			isNaN(parseFloat(val))
		) {
			return "-";
		}
		if (["number", "string"].includes(typeof val)) {
			if (val == 0) return 0;
			if (String(val).match(/\./) || String(val).includes("e-")) {
				let newVal = parseFloat(Number(val).toFixed(length));
				if (newVal === 0) return 0; //转换后有可能是 -0
				return newVal;
			} else {
				return val;
			}
		}
		return "-";
	}
};
