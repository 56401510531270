<template>
	<a-config-provider :getPopupContainer="getPopupContainer" :locale="locale">
		<router-view />
	</a-config-provider>
</template>
<script>
	import zhCN from "ant-design-vue/es/locale/zh_CN";
	export default {
		name: "App",
		data() {
			return {
				locale: zhCN
			};
		},
		methods: {
			getPopupContainer(el, dialogContext) {
				if (dialogContext) {
					return dialogContext.getDialogWrap();
				} else {
					return document.body;
				}
			}
		}
	};
</script>

<style lang="scss">
	.ant-table-placeholder{
		border-bottom: none!important;
		padding: 50px 16px!important;
	}
</style>