export default {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					table_loading: false,
					table_col: [],
					table_data: [],
					table_pagination: {
						total: 0,
						current: 1,
						pageSize: 10,
						showSizeChanger: true, // 显示可改变每页数量
						pageSizeOptions: ["10", "20", "30"],
						onShowSizeChange: (current, pageSize) =>
							(this.table_pagination.pageSize = pageSize) // 改变每页数量时更新显示
					}
				};
			},
			methods: {
				tableChange(pagination, filters, sorter) {
					console.log("tableChange", pagination, filters, sorter);
					this.table_pagination.current = pagination.current;
					// table 统一
					this.getTableList && this.getTableList();
				}
			}
		});
	}
};
